<template>
  <div>
    <el-card class="box-card">
      <div class="card-header">
        <el-form :label-width="200">
          <el-form-item label="设备IMEI">
            <el-input :placeholder="$t('notice.enter_imei')" v-model="imei"></el-input>
          </el-form-item>
          <el-form-item :label="$t('notice.message')">
            <el-input :placeholder="$t('notice.input_message')" type="textarea" :rows="6" :show-word-limit="true"
                      :maxlength="40"
                      v-model="content"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleSubmit" type="primary">{{ $t('notice.submit') }}</el-button>
            <!--            <el-button @click="resetForm" type="primary">重置</el-button>-->
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<script>
import device from "../../model/device";

export default {
  name: "notice",
  data() {
    return {
      imei: '',
      content: ''
    }
  },
  mounted() {
  },
  methods: {
    async handleSubmit() {
      const ret = await device.notice({imei: this.imei, content: this.content})
      if (ret.code === 200) {
        this.imei = ''
        this.content = ''
        this.$message.success(ret.message);
      } else {
        this.$message.error(ret.message);
      }
    }
  }
};
</script>
<style scoped>
</style>
